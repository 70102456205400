import { bindable, bindingMode, containerless } from "aurelia-framework";
import { FinanceData } from "models/FinanceData";

type props = {
    expense: number,
};

@containerless
export class UnitFinanceExpense {
    @bindable({ defaultBindingMode: bindingMode.oneWay }) financeData: FinanceData;
    initialData: props;
}
