import { bindable, bindingMode, containerless } from "aurelia-framework";
import { Money } from "models/Money";
import { FinanceData } from "models/FinanceData";
import { Currency } from "models/Currency";

type props = {
    collection: number,
    nisabPaidToCentral: number
};

@containerless
export class UnitFinanceNisab {
    @bindable({ defaultBindingMode: bindingMode.oneWay }) financeData: FinanceData;
    initialData: props;

    getProps(dto: FinanceData) {
        const propsData: props = {
            collection: dto.collection.amount,
            nisabPaidToCentral: dto.nisabPaidToCentral.amount
        };
        return propsData;
    }

    financeDataChanged(newValue: FinanceData, oldValue: FinanceData) {
        this.initialData = this.getProps(newValue);
    }

    get isDirty() {
        const latestData: props = this.getProps(this.financeData);

        return JSON.stringify(this.initialData) !== JSON.stringify(latestData);
    }

    get newNisabPaidToCentral() {
        // Set `Collection` amount as the `Nisab Paid` amount.
        const newValue = this.financeData.collection.amount;
        this.financeData.nisabPaidToCentral.amount = newValue;

        return new Money(newValue, Currency.AUD);
    }
}
